import { Container } from "@mui/material";
import Spinner from "src/components/branded/Spinner";
import { useSelectedPartner } from "src/components/contexts/SelectedPartnerContext";
import Page from "src/components/Page";
import PublicPageEdit from "src/features/partnerPublicPage/PublicPageEdit";
import { usePartner } from "src/hooks/partner";

export default function PublicPageEditPage() {
  const { selectedPartnerId } = useSelectedPartner();

  const partnerQuery = usePartner(selectedPartnerId);
  const partner = partnerQuery.data;

  if (partnerQuery.isLoading) {
    return <Spinner />;
  }

  return (
    <Page
      title={"Public Page Edit"}
      breadcrumbs={[
        { label: "Partners", route: "/portal/partner/switch" },
        { label: partnerQuery.data?.name, route: "/portal/partner/dashboard" },
        { label: "Public Page Edit" },
      ]}
    >
      <Container>
        <PublicPageEdit partner={partner} />
      </Container>
    </Page>
  );
}
