import { Button, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import HookFormTextField from "src/components/hookform/HookFormTextField";
import HookFormEditor from "src/components/hookform/HookFormEditor";

Yup.addMethod(Yup.string, "nullToEmptyIfRequiredAndNotNullable", function () {
  return this.transform(function (value, originalValue) {});
});

const schema = Yup.object().shape({
  name: Yup.string().ensure().required("Name is required"),
  addressLine1: Yup.string().ensure().required("Address line 1 is required."),
  addressLine2: Yup.string().nullable(),
  city: Yup.string().ensure().required("City is required."),
  state: Yup.string().ensure().required("State is required."),
  zip: Yup.string().ensure().required("Zip is required."),
  phone: Yup.string().nullable(),
  slug: Yup.string()
    .ensure()
    .required("URL Name is required")
    .matches(
      /^[a-z0-9-]+$/,
      "Url can only contain lowercase letters, numbers, and dashes"
    ),
  contactEmail: Yup.string().email().nullable(),
  contentAsQuill: Yup.object().nullable(),
});

export default function EditBasicInfoForm({
  partner,
  onSave,
  onCancel,
  submitButtonText = "Save",
  cancelButtonText = "Cancel",
  editContactAndLocation = false,
  editDescription = false,
}) {
  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: partner,
  });

  const {
    handleSubmit,
    formState,
    formState: { isSubmitting },
  } = formMethods;

  return (
    <form onSubmit={handleSubmit(onSave)} autoComplete="off">
      <Stack direction="column" spacing={2} sx={{ minWidth: "300px", mt: 1 }}>
        {editContactAndLocation && (
          <>
            <HookFormTextField
              name="name"
              label="Name"
              fullWidth
              formMethods={formMethods}
            />
            <HookFormTextField
              name="addressLine1"
              label="Address Line 1"
              fullWidth
              formMethods={formMethods}
            />
            <HookFormTextField
              name="addressLine2"
              label="Address Line 2"
              fullWidth
              formMethods={formMethods}
            />
            <HookFormTextField
              name="city"
              label="City"
              fullWidth
              formMethods={formMethods}
            />
            <HookFormTextField
              name="state"
              label="State"
              fullWidth
              formMethods={formMethods}
            />
            <HookFormTextField
              name="zip"
              label="Zip Code"
              fullWidth
              formMethods={formMethods}
            />
            <HookFormTextField
              name="phone"
              label="Phone"
              fullWidth
              formMethods={formMethods}
            />
            <HookFormTextField
              name="slug"
              label="URL Name"
              helperText={"This will be used in the URL for this page."}
              fullWidth
              formMethods={formMethods}
            />
            <HookFormTextField
              name="contactEmail"
              label="Contact Email"
              fullWidth
              formMethods={formMethods}
            />
          </>
        )}
        {/* Additional fields for update mode */}
        {editDescription && (
          <HookFormEditor
            name="contentAsQuill"
            label="Description"
            placeholder="Enter a description."
            formMethods={formMethods}
            wordCountWarning={false}
          />
        )}

        <Stack direction="row" spacing={2} justifyContent="center">
          <Button color="primary" variant="outlined" onClick={onCancel}>
            {cancelButtonText}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!formState.isValid && formState.submitCount > 0}
          >
            {submitButtonText}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
}
