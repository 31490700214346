import { Container } from "@mui/material";
import Spinner from "src/components/branded/Spinner";
import { useSelectedPartner } from "src/components/contexts/SelectedPartnerContext";
import Page from "src/components/Page";
import PartnerUsers from "src/features/partnerUsers/PartnerUsers";
import { usePartner } from "src/hooks/partner";

export default function PartnerUsersPage() {
  const { selectedPartnerId } = useSelectedPartner();

  const partnerQuery = usePartner(selectedPartnerId);
  const partner = partnerQuery.data;

  if (partnerQuery.isLoading) {
    return <Spinner />;
  }

  return (
    <Page
      title={"Partner Users"}
      breadcrumbs={[
        { label: "Partners", route: "/portal/partner/switch" },
        { label: partnerQuery.data?.name, route: "/portal/partner/dashboard" },
        { label: "Users" },
      ]}
    >
      <Container>
        <PartnerUsers partnerId={selectedPartnerId} partner={partner} />
      </Container>
    </Page>
  );
}
