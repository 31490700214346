import React from "react";
//import Page from "src/components/Page";
import { Box, Stack } from "@mui/material";
import { resolvePartnerPublicPageHeroImageSrc } from "src/utils/imageLinks";
import { resolvePartnerPublicPageLogoImageSrc } from "src/utils/imageLinks";
import QuillView from "src/components/QuillView";
import "react-quill/dist/quill.bubble.css";
import Hero from "src/components/Hero";

export default function PublicPageContent({
  partner,
  editBasicInfo,
  editHeroButton,
  editDescriptionButton,
  editLogoButton,
}) {
  let partnerDetails = "";

  if (partner.addressLine1) {
    partnerDetails += `${partner.addressLine1}`;
    if (partner.addressLine2) {
      partnerDetails += ` ${partner.addressLine2}\n`;
    } else {
      partnerDetails += "\n";
    }
  }

  if (partner.city && partner.state && partner.zip) {
    partnerDetails += `${partner.city}, ${partner.state} ${partner.zip}\n`;
  }

  if (partner.phone) {
    partnerDetails += `Phone: ${partner.phone}\n`;
  }

  if (partner.contactEmail) {
    partnerDetails += `Email: ${partner.contactEmail}`;
  }

  return (
    <Stack spacing={2}>
      <Hero
        headline={<>{partner.name}</>}
        subtext={partnerDetails}
        splitSubtext
        imageWithoutExtension={resolvePartnerPublicPageHeroImageSrc(
          partner.id,
          partner.heroImageFileNamePrefix ?? "/static/homepage/hero"
        )}
        logoImageSrc={resolvePartnerPublicPageLogoImageSrc(
          partner.id,
          partner.logoFileNamePrefix
        )}
        logoImage={partner.logoFileNamePrefix}
        editBasicInfo={editBasicInfo}
        editHeroButton={editHeroButton}
        editLogoButton={editLogoButton}
      />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {editDescriptionButton}
      </Box>
      <QuillView value={partner.contentAsQuill} sx={{ paddingTop: 2 }} />
    </Stack>
  );
}
