import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  // Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  useAddPartnerUserRole,
  useDisablePartnerUser,
  useInfinitePartnerUserList,
  usePartnerInviteList,
  useRemovePartnerUserRole,
} from "src/hooks/partner";
import Spinner from "src/components/branded/Spinner";
import Iconify from "src/components/Iconify";
import { InviteUserListDialog } from "./InviteUserListDialog";
import { InviteUserModal } from "./InviteUserModal";
import AddRoleModal from "src/features/AddRoleModal";
import { usePartnerRoleList } from "src/hooks/role";
import { useSnackbar } from "notistack";
import ListActionBar from "src/components/ListActionBar";

export default function PartnerUsers({
  partnerId,
  partner = { locations: [] }, // What did this line do originally? Did I break something?
}) {
  const { enqueueSnackbar } = useSnackbar();
  // component state
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [inviteListDialogOpen, setInviteListDialogOpen] = useState(false);
  const [addRoleOpen, setAddRoleOpen] = useState(false);
  const [addingRoleForUser, setAddingRoleForUser] = useState({});
  const [removeInProgress, setRemoveInProgress] = useState([]);
  const [disableInProgress, setDisableInProgress] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    status: "active",
    numxample: "",
  });

  // query and mutation hooks
  const partnerRoles = usePartnerRoleList();
  const partnerInviteList = usePartnerInviteList(partnerId);
  const addRole = useAddPartnerUserRole();
  const removeRole = useRemovePartnerUserRole();
  const disableUser = useDisablePartnerUser();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    status,
  } = useInfinitePartnerUserList(partnerId, { search, status: filter.status });

  useEffect(() => {
    refetch();
  }, [search, filter, refetch]);

  const loadMore = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const partnerUsers = useMemo(
    () => data?.pages.flatMap((page) => page.entries) || [],
    [data]
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage,
    onLoadMore: loadMore,
  });

  const pendingInviteCount = partnerInviteList.isLoading
    ? "Loading "
    : partnerInviteList.data.filter((x) => {
        const expireDate = new Date(x.expires);
        return expireDate > Date.now() && !x.accepted;
      }).length;

  const handlePartnerUserSearch = (searchTerm) => {
    setSearch(searchTerm);
  };

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  const handleRemoveUserRole = (userId, roleId) => {
    setRemoveInProgress(removeInProgress.concat({ userId, roleId }));
    removeRole.mutate(
      { partnerId, userId, roleId },
      {
        onSuccess: () => {
          enqueueSnackbar("Role Removed", {
            variant: "success",
            autoHideDuration: 2000,
          });
        },
        onSettled: () => {
          setRemoveInProgress((val) =>
            val.filter((x) => x.userId !== userId && x.roleId !== roleId)
          );
        },
      }
    );
  };

  const handleRoleOpen = (user) => {
    setAddRoleOpen(true);
    setAddingRoleForUser(user);
  };

  const handleAddRole = (userId, roleId) => {
    addRole.mutate(
      {
        partnerId,
        addRoleModel: { userId, roleId },
      },
      {
        onSuccess: () => {
          setAddRoleOpen(false);
          enqueueSnackbar("Added Role", {
            variant: "success",
            autoHideDuration: 2000,
          });
        },
        onError: (error) => {
          enqueueSnackbar(error, {
            variant: "error",
            autoHideDuration: 2000,
          });
        },
      }
    );
  };

  const handleDisableUser = (userId) => {
    setDisableInProgress(disableInProgress.concat({ userId }));
    disableUser.mutate(
      { partnerId, userId },
      {
        onSuccess: () => {
          enqueueSnackbar("User Disabled", {
            variant: "success",
            autoHideDuration: 2000,
          });
        },
        onSettled: () => {
          setDisableInProgress((val) => val.filter((x) => x.userId !== userId));
        },
      }
    );
  };

  return (
    <>
      {addRoleOpen && (
        <AddRoleModal
          open={addRoleOpen}
          user={addingRoleForUser}
          close={() => setAddRoleOpen(false)}
          roles={partnerRoles.data}
          handleAddRole={handleAddRole}
        />
      )}
      <InviteUserModal
        partnerId={partnerId}
        open={inviteDialogOpen}
        close={() => setInviteDialogOpen(false)}
        roles={partnerRoles.data}
      />
      <InviteUserListDialog
        partnerId={partnerId}
        open={inviteListDialogOpen}
        close={() => setInviteListDialogOpen(false)}
        invitations={partnerInviteList.data}
      />
      <Stack direction="column" spacing={2} marginTop={2} maxWidth={"md"}>
        <ListActionBar
          actionButtons={[
            {
              label: "Invite Users",
              variant: "outlined",
              onClick: () => setInviteDialogOpen(true),
            },
            {
              label: `View Pending Invitations (${pendingInviteCount})`,
              variant: "text",
              onClick: () => setInviteListDialogOpen(true),
            },
          ]}
          onSearch={handlePartnerUserSearch}
          searchPlaceholder={`Find by name/email`}
          filters={filter}
          onFilterChange={handleFilterChange}
          filterOptions={[
            {
              key: "status",
              type: "radio",
              label: "Status",
              closeOnChange: true,
              options: [
                { value: "active", label: "Active" },
                { value: "disabled", label: "Disabled" },
              ],
            },
          ]}
        />
        {status === "loading" ? (
          <Spinner />
        ) : !partnerUsers.length ? (
          <Typography>No users exist yet. Invite users above.</Typography>
        ) : (
          <Fragment>
            {filter.status === "active" && (
              <ActiveUserTable
                userData={partnerUsers.filter((x) => x.active)}
                removeInProgress={removeInProgress}
                handleRemoveUserRole={handleRemoveUserRole}
                handleRoleOpen={handleRoleOpen}
                disableInProgress={disableInProgress}
                handleDisableUser={handleDisableUser}
              />
            )}
            {filter.status === "disabled" && (
              <DisabledUserTable
                userData={partnerUsers.filter((x) => !x.active)}
                handleRoleOpen={handleRoleOpen}
              />
            )}
          </Fragment>
        )}
        {/* Infinite scroll trigger */}
        {(isFetchingNextPage || hasNextPage) && (
          <Box ref={sentryRef} sx={{ textAlign: "center", my: 2 }}>
            <Spinner />
          </Box>
        )}
      </Stack>
    </>
  );
}

function ActiveUserTable({
  userData,
  removeInProgress,
  handleRemoveUserRole,
  handleRoleOpen,
  disableInProgress,
  handleDisableUser,
}) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>Add Role</TableCell>
            <TableCell>Deactivate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userData.map((user) => (
            <TableRow key={user.id} hover>
              <TableCell>{user.userName}</TableCell>
              <TableCell>{user.firstName}</TableCell>
              <TableCell>{user.lastName}</TableCell>
              <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
                <Stack spacing={0} direction="column">
                  {user.roles.map((role) => (
                    <Box
                      key={role.id}
                      justifyContent="space-between"
                      alignItems="center"
                      display="flex"
                    >
                      {role.name}
                      {removeInProgress.some(
                        (x) => x.roleId === role.id && x.userId === user.id
                      ) ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Tooltip title="Remove">
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() =>
                              handleRemoveUserRole(user.id, role.id)
                            }
                          >
                            <Iconify icon="eva:trash-2-fill" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  ))}
                </Stack>
              </TableCell>
              <TableCell>
                {" "}
                <Tooltip title="Add Role">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleRoleOpen(user)}
                  >
                    <Iconify icon="eva:plus-circle-fill" />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>
                {disableInProgress.some((x) => x.userId === user.id) ? (
                  <CircularProgress size={20} />
                ) : (
                  <Tooltip title="Disable User">
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDisableUser(user.id)}
                    >
                      <Iconify icon="eva:trash-2-fill" />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function DisabledUserTable({ userData, handleRoleOpen }) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Reactivate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userData.map((user) => (
            <TableRow key={user.id} hover>
              <TableCell>{user.userName}</TableCell>
              <TableCell>{user.firstName}</TableCell>
              <TableCell>{user.lastName}</TableCell>
              <TableCell>
                {" "}
                <Tooltip title="Reactivate">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleRoleOpen(user)}
                  >
                    <Iconify icon="eva:plus-circle-fill" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
