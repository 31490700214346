import { Box } from "@mui/material";
import MainMenuLink from "./MainMenuLink";
import { useCurrentUser } from "src/hooks/user";
import { Fragment } from "react";
import { useSelectedPartner } from "./contexts/SelectedPartnerContext";

export default function TopNavSection({ navConfig, sx }) {
  const userQuery = useCurrentUser();
  const user = userQuery.data;
  const { selectedPartnerId } = useSelectedPartner();
  return (
    <Box
      sx={(theme) => ({
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        alignItems: "center",
        alignText: "center",
        justifyContent: "flex-end",
        display: "flex",
        flexGrow: 1,
        ...sx,
      })}
    >
      {navConfig.map((item) => {
        if (
          item.canAccess &&
          !item.canAccess({ user, partnerId: selectedPartnerId })
        ) {
          return <Fragment key={item.title} />;
        }
        return (
          <MainMenuLink
            key={item.title}
            path={item.path}
            title={item.title}
            children={item.children || []}
            user={user}
            partnerId={selectedPartnerId}
          />
        );
      })}
    </Box>
  );
}
