import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import PublicPage from "./PublicPageContent";
import {
  useUpdatePartnerHeroImage,
  useUpdatePartnerLogo,
  useUpdatePublicPageInfo,
} from "src/hooks/partner";
import palette from "src/theme/palette";
import { useState } from "react";
import EditBasicInfoForm from "src/features/partnerPublicPage/EditBasicInfoForm";
import { useSnackbar } from "notistack";
import EditPrimaryPhoto from "src/components/EditPrimaryPhoto";
import { resolvePartnerPublicPageImageSrc } from "src/utils/imageLinks";

export default function PublicPageEdit({ partner }) {
  const [editBasicInfo, setEditBasicInfo] = useState(false);
  const [editQuill, setEditQuill] = useState(false);
  const [editImages, setEditImages] = useState(false);
  const [editLogo, setEditLogo] = useState(false);
  const [editHeroImage, setEditHeroImage] = useState(false);

  return (
    <Stack direction="column">
      <Stack direction="row" spacing={1}>
        <Tooltip title="Edit Basic Info">
          <IconButton
            size="large"
            color="primary"
            onClick={() => {
              setEditBasicInfo(true);
            }}
          >
            <Iconify icon="eva:edit-fill" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit Description">
          <IconButton
            size="large"
            color="primary"
            onClick={() => {
              setEditQuill(true);
            }}
          >
            <Iconify icon="simple-icons:reacthookform" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit Images">
          <IconButton
            size="large"
            color="primary"
            onClick={() => {
              setEditImages(true);
            }}
          >
            <Iconify icon="mdi:camera" />
          </IconButton>
        </Tooltip>
      </Stack>
      {editBasicInfo && (
        <EditBasicInfoDialog
          partner={partner}
          open={editBasicInfo}
          onClose={() => setEditBasicInfo(false)}
          editContactAndLocation
        />
      )}
      {editQuill && (
        <EditBasicInfoDialog
          partner={partner}
          open={editQuill}
          onClose={() => setEditQuill(false)}
          editDescription
        />
      )}
      {editImages && (
        <EditPartnerImageDialog
          partner={partner}
          open={editImages}
          onClose={() => setEditImages(false)}
          showHero
          showLogo
        />
      )}
      {editHeroImage && (
        <EditPartnerImageDialog
          partner={partner}
          open={editHeroImage}
          onClose={() => setEditHeroImage(false)}
          showHero
        />
      )}
      {editLogo && (
        <EditPartnerImageDialog
          partner={partner}
          open={editLogo}
          onClose={() => setEditLogo(false)}
          showLogo
        />
      )}
      <PublicPage
        partner={partner}
        editBasicInfo={
          <Button
            variant="outlined"
            sx={{
              width: "96%",
              height: "100%",
              border: "none",
              borderColor: "transparent",
              color: "transparent",
              fontSize: "40px",
              mx: "0px",
              "&:hover": {
                border: "unset",
                backgroundColor: "rgba(0,0,0,.6)",
                color: palette.primary.dark,
                boxShadow: "0 0 15px",
              },
            }}
            onClick={() => {
              setEditBasicInfo(true);
            }}
          >
            <Stack direction="column" alignItems={"center"}>
              <Iconify
                icon="eva:edit-fill"
                sx={{ width: "5rem", height: "5rem" }}
              />
              Edit Basic Info
            </Stack>
          </Button>
        }
        editHeroButton={
          <Tooltip title="Edit Hero">
            <IconButton
              size="large"
              color="primary"
              onClick={() => {
                setEditHeroImage(true);
              }}
            >
              <Iconify icon="mdi:camera" />
            </IconButton>
          </Tooltip>
        }
        editLogoButton={
          <Button
            variant="outlined"
            sx={{
              position: "absolute",
              top: "54%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "50%",
              width: "95px",
              height: "95px",
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: "transparent",
              fontSize: "20px",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                color: "primary.dark",
                boxShadow: "0 0 15px",
              },
            }}
            onClick={() => {
              setEditLogo(true);
            }}
          >
            Edit Logo
          </Button>
        }
        editDescriptionButton={
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              height: "auto",
              border: "2px solid rgba(0,0,0,0.2)",
              backgroundColor: "rgba(0,0,0,0.05)",
              color: "rgba(0,0,0,0.7)",
              fontSize: "20px",
              "&:hover": {
                border: "2px solid rgba(0,0,0,0)",
                backgroundColor: "rgba(0,0,0,.6)",
                color: palette.primary.dark,
                boxShadow: "0 0 15px",
              },
            }}
            onClick={() => {
              setEditQuill(true);
            }}
          >
            <Stack direction="column" alignItems={"center"}>
              <Iconify
                icon="simple-icons:reacthookform"
                sx={{ width: "2rem", height: "2rem" }}
              />
              Edit Description
            </Stack>
          </Button>
        }
      />
    </Stack>
  );
}

function EditBasicInfoDialog({
  open,
  onClose,
  partner,
  editContactAndLocation,
  editDescription,
}) {
  const updatePartnerPublicPage = useUpdatePublicPageInfo();
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = async (partner) => {
    try {
      await updatePartnerPublicPage.mutateAsync({
        partnerId: partner.id,
        partner,
      });

      enqueueSnackbar("Updated Public Page", {
        variant: "success",
        autoHideDuration: 2000,
      });
      onClose();
    } catch (error) {
      enqueueSnackbar(error || "An error occurred", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle>Edit Basic Info</DialogTitle>
      <DialogContent>
        <EditBasicInfoForm
          partner={partner}
          editContactAndLocation={editContactAndLocation}
          editDescription={editDescription}
          onSave={handleSave}
          onCancel={onClose}
        />
      </DialogContent>
    </Dialog>
  );
}

function EditPartnerImageDialog({
  partner,
  open,
  onClose,
  showHero = false,
  showLogo = false,
}) {
  const updatePartnerHeroImage = useUpdatePartnerHeroImage();
  const updatePartnerLogo = useUpdatePartnerLogo();

  const handleUpdatePartnerHero = async ({ objectId, file }, events) => {
    return updatePartnerHeroImage.mutateAsync({ ...objectId, file }, events);
  };

  const handleUpdatePartnerLogo = async ({ objectId, file }, events) => {
    try {
      const result = await updatePartnerLogo.mutateAsync(
        { ...objectId, file },
        events
      );
      return result;
    } catch (error) {
      //console.log("error", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle>
        {showHero && showLogo
          ? "Update Public Page Images"
          : showHero
          ? "Update Hero Image"
          : "Update Logo"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: "absolute", right: 8, top: 8, color: "gray" }}
      >
        <Iconify icon="eva:close-outline" width={20} height={20} />
      </IconButton>
      <DialogContent>
        {showHero && (
          <>
            <Typography variant="h6" sx={{ marginBottom: 2, marginLeft: 3 }}>
              {showLogo ? "Add Hero Image" : "Hero Image"}
            </Typography>
            <EditPrimaryPhoto
              namespace="partner"
              objectId={{ partnerId: partner.id }}
              updatePrimaryImage={handleUpdatePartnerHero}
              isPrimaryImageLoading={updatePartnerHeroImage.isLoading}
              primaryImageFileNamePrefix={partner?.heroImageFileNamePrefix}
              imageResolveFunction={resolvePartnerPublicPageImageSrc}
              isHeroImage
            />
          </>
        )}
        {showLogo && (
          <>
            {showHero && (
              <Typography
                variant="h6"
                sx={{ marginBottom: 2, marginTop: 2, marginLeft: 3 }}
              >
                Add Logo
              </Typography>
            )}
            <EditPrimaryPhoto
              namespace="partner"
              objectId={{ partnerId: partner.id }}
              updatePrimaryImage={handleUpdatePartnerLogo}
              isPrimaryImageLoading={updatePartnerLogo.isLoading}
              primaryImageFileNamePrefix={partner?.logoFileNamePrefix}
              imageResolveFunction={resolvePartnerPublicPageImageSrc}
              isLogoImage
            />
          </>
        )}
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          marginTop={4}
          marginRight={2}
        ></Stack>
      </DialogContent>
    </Dialog>
  );
}
