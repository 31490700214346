import { TextField, MenuItem } from "@mui/material";
//import { useCallback } from "react";
import { Controller } from "react-hook-form";
// ----------------------------------------------------------------------

export default function HookFormTextField({
  name,
  label,
  helperText,
  formMethods,
  onChange: onChangeHandler = () => {},
  deps = [],
  options,
  children,
  select = false,
  ...props
}) {
  const {
    control,
    trigger,
    formState: { errors },
  } = formMethods;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          id={name}
          name={name}
          label={label || name}
          helperText={errors[name]?.message || helperText || ""}
          error={errors[name] && Boolean(errors[name])}
          value={value || (value === 0 ? 0 : "")}
          onChange={(value) => {
            deps.forEach((x) => trigger(x));
            onChange(value);
            onChangeHandler(value);
          }}
          select={select}
          {...props}
        >
          {select && options
            ? options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))
            : children}
        </TextField>
      )}
    />
  );
}
