import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// material
import { alpha, useTheme } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
//
import Iconify from "./Iconify";
import { useCurrentUser } from "src/hooks/user";
import { useSelectedPartner } from "./contexts/SelectedPartnerContext";

// ----------------------------------------------------------------------

function NavSectionListItem({ sx, ...props }) {
  return (
    <ListItemButton
      disableGutters
      {...props}
      sx={(theme) => ({
        ...theme.typography.body2,
        height: 48,
        position: "relative",
        textTransform: "capitalize",
        color: theme.palette.text.secondary,
        borderRadius: theme.shape.borderRadius,
        ...sx,
      })}
    />
  );
}

function NavSectionIcon({ sx, children, ...props }) {
  return (
    <ListItemIcon
      sx={{
        width: 22,
        height: 22,
        color: "inherit",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
      {...props}
    >
      {children}
    </ListItemIcon>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const userQuery = useCurrentUser();
  const user = userQuery.data;
  const { selectedPartnerId } = useSelectedPartner();
  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children, canAccess } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  };

  if (canAccess && !canAccess({ user, partnerId: selectedPartnerId })) {
    return <div />;
  }
  if (children) {
    return (
      <>
        <NavSectionListItem
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <NavSectionIcon>{icon && icon}</NavSectionIcon>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={
              open
                ? "eva:arrow-ios-downward-fill"
                : "eva:arrow-ios-forward-fill"
            }
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </NavSectionListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path, children, icon } = item;
              return (
                <NavItemRecursive
                  key={title}
                  title={title}
                  path={path}
                  children={children}
                  icon={icon}
                  onClick={handleOpen}
                  user={user}
                  partnerId={selectedPartnerId}
                  active={active}
                />
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <NavSectionListItem
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <NavSectionIcon>{icon && icon}</NavSectionIcon>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </NavSectionListItem>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}

function NavItemRecursive({
  title,
  path,
  children = [],
  icon,
  onClick,
  user,
  partnerId,
  active,
  pl = 2,
}) {
  const isActive = active(path);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };
  // are we a parent link?
  if (children.length > 0) {
    return (
      <>
        <NavSectionListItem
          onClick={() => setSubmenuOpen(!submenuOpen)}
          sx={{
            pl: pl,
            ...(isActive && activeSubStyle),
          }}
        >
          <NavSectionIcon>{icon && icon}</NavSectionIcon>
          <ListItemText disableTypography primary={title} />
          <Iconify
            icon={
              submenuOpen
                ? "eva:arrow-ios-downward-fill"
                : "eva:arrow-ios-forward-fill"
            }
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </NavSectionListItem>
        <Collapse in={submenuOpen} timeout="auto" unmountOnExit>
          {children.map(({ title, path, canAccess, children }) => {
            if (canAccess && !canAccess({ user, partnerId })) {
              return <Fragment key={title} />;
            } else {
              return (
                <NavItemRecursive
                  key={title}
                  title={title}
                  path={path}
                  children={children || []}
                  onClick={() => {
                    setSubmenuOpen(false);
                    onClick();
                  }}
                  user={user}
                  partnerId={partnerId}
                  active={active}
                  pl={pl + 2}
                />
              );
            }
          })}
        </Collapse>{" "}
      </>
    );
  } else {
    return (
      <NavSectionListItem
        key={title}
        component={RouterLink}
        to={path}
        sx={{
          pl: pl,
          ...(isActive && activeSubStyle),
        }}
      >
        <NavSectionIcon>
          <Box
            component="span"
            sx={{
              width: 4,
              height: 4,
              display: "flex",
              borderRadius: "50%",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </NavSectionIcon>
        <ListItemText disableTypography primary={title} />
      </NavSectionListItem>
    );
  }
}
