import { MenuItem, Stack, styled } from "@mui/material";
import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import useResponsive from "src/hooks/useResponsive";
import { Link as RouterLink } from "react-router-dom";
import MenuAccordion from "./MenuAccordian";
import MenuPopover from "./MenuPopover";

export const sharedLinkStyle = (props) => {
  return {
    margin: props.theme.spacing(1.5),
    marginRight: props.theme.spacing(1.5),
    display: "flex",
    textDecoration: "none",
    color: "black",
    padding: 0,
    "&:hover": {
      color: props.theme.palette.primary.dark,
      cursor: "pointer",
    },
    "&.active": {
      color: props.theme.palette.primary.darker,
      borderBottomStyle: "solid",
      borderBottomWidth: "thin",
    },
  };
};

const NavLinkStyled = styled(NavLink)((props) => sharedLinkStyle(props));
const LinkToNowhere = styled("span")((props) => sharedLinkStyle(props));

const StyledLink = ({ to, children, onClick, ...other }) => {
  if (to) {
    return (
      <NavLinkStyled to={to} onClick={onClick} {...other}>
        {children}
      </NavLinkStyled>
    );
  } else {
    return (
      <LinkToNowhere onClick={onClick} {...other}>
        {children}
      </LinkToNowhere>
    );
  }
};

export default function MainMenuLink({
  title,
  path,
  user,
  partnerId,
  children = [],
}) {
  const [openAnchor, setOpenAnchor] = useState(null);
  const isDesktop = useResponsive("up", "lg");

  // are we a parent link?
  if (children.length > 0) {
    if (isDesktop) {
      return (
        <>
          <StyledLink
            to={path}
            onClick={(e) => {
              setOpenAnchor(e.currentTarget);
              e.preventDefault();
            }}
          >
            {title}
          </StyledLink>
          <MenuPopover
            onClose={() => setOpenAnchor(null)}
            open={!!openAnchor}
            anchorEl={openAnchor}
            sx={{
              p: 0,
              mt: 1.5,
              ml: 0.75,
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.75,
              },
            }}
          >
            {children.map(({ title, path, canAccess, children }) => {
              if (canAccess && !canAccess({ user, partnerId })) {
                return <Fragment key={title} />;
              } else {
                return (
                  <MainMenuLinkRecursive
                    title={title}
                    path={path}
                    key={title}
                    children={children}
                    onClick={() => setOpenAnchor(null)}
                    user={user}
                    partnerId={partnerId}
                  />
                );
              }
            })}
          </MenuPopover>
        </>
      );
    } else {
      return (
        <Stack alignItems="center">
          <StyledLink
            to={path}
            onClick={(e) => {
              setOpenAnchor(openAnchor ? null : e.currentTarget);
              e.preventDefault();
            }}
            className={openAnchor ? "active" : ""}
          >
            {title}
          </StyledLink>
          <MenuAccordion open={!!openAnchor}>
            {children.map(({ title, path, children }) => (
              <MainMenuLinkRecursive
                title={title}
                path={path}
                key={title}
                children={children}
                onClick={() => setOpenAnchor(null)}
              />
            ))}
          </MenuAccordion>
        </Stack>
      );
    }
  } else {
    return <StyledLink to={path}>{title}</StyledLink>;
  }
}

function MainMenuLinkRecursive({
  title,
  path,
  children = [],
  onClick,
  user,
  partnerId,
}) {
  const [submenuOpen, setSubmenuOpen] = useState(false);
  // are we a parent link?
  if (children.length > 0) {
    return (
      <MenuItem
        onClick={(e) => {
          e.preventDefault();
          setSubmenuOpen(!submenuOpen);
        }}
        sx={{
          display: "block",
        }}
      >
        {title}
        <MenuAccordion open={submenuOpen}>
          {children.map(({ title, path, canAccess, children }) => {
            if (canAccess && !canAccess({ user, partnerId })) {
              return <Fragment key={title} />;
            } else {
              return (
                <MainMenuLinkRecursive
                  key={title}
                  title={title}
                  path={path}
                  children={children || []}
                  onClick={() => {
                    setSubmenuOpen(false);
                    onClick();
                  }}
                  user={user}
                />
              );
            }
          })}
        </MenuAccordion>
      </MenuItem>
    );
  } else {
    return (
      <MenuItem to={path} key={title} component={RouterLink} onClick={onClick}>
        {title}
      </MenuItem>
    );
  }
}
